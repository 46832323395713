<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style scoped>
  .card-body {
    padding:1.25rem 8rem
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    newsDetail, newsViewCount
  } from "@/api/web/news.js";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,
    },
    data() {
      return {
        title: "新闻详情",
        items: [{
            text: "用户首页",
            href: "/admin"

          },
          {
            text: "新闻详情",
            active: true
          }
        ],
        newsDetails: {}
      };
    },
    methods: {
      getDetails() {
        newsDetail(this.$route.query.newsId).then(res => {
          if (res.status) {
            if (res.data.newsSort == 'url') {
              window.open(res.data.newsUrl, "_self");
            } else {
              res.data.newsTitle = res.data.newsTitle.replace(/\n/g,'<br>')
              this.newsDetails = res.data
            }
            // 更新浏览量
            newsViewCount(this.$route.query.newsId)
          }
        })
      },
      goBack() {
        window.history.back()

      }
    },
    mounted() {
      this.getDetails()
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 165px;">
          <div class="card-body">

            <div class="news-left">
              <div class="left-top">
                <h3 class="news-title" style="color: rgb(7, 77, 140);" v-html="newsDetails.newsTitle"></h3>
                <div class="top-info">
                  <span style="margin-right: 40px">发布日期：{{(newsDetails.createTime||'').substring(0,10)}}</span>
                  <span style="margin-right: 40px" v-if="newsDetails.newsFrom">来源：{{newsDetails.newsFrom}}</span>
                  <span>浏览次数：<span>{{newsDetails.viewCount}}</span></span>
                <a href="javascript:void(0);" @click="goBack()">  <div class="return-btn">
                    <img src="@/assets/images/person/returnbtn.png"
                        alt="" class="imgin">
                  </div></a>
                </div>
              </div>
              <div class="left-main" v-html="newsDetails.newsContent">
              </div>
            </div>
          </div>
        </div>



      </div>

    </div>


  </Layout>
</template>

<style scoped>
  /deep/ .left-main table {
    width: 90% !important;
    margin: 0 auto !important;
    border: 1px solid #ccc !important;
    border-collapse: collapse;
  }

  /deep/ .left-main table td,
  .left-main table th {
    text-align: center;
    padding: 5px 0;
    border: 1px solid #ccc !important;
  }

  /deep/ .left-main p {
    line-height: 32px;
    font-size: 16px;
    margin: 0;
  }

  /deep/ .left-main p img {
    display: initial;
    max-width: 85% !important;
    height: auto !important;
  }

  .peizi {
    text-indent: 5px;
    background: #000;
    height: 50px;
    line-height: 50px;
    width: 100%;
    color: #fff;
    font-size: 14px;
    filter: Alpha(opacity=60);
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 4px;
    left: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .bannerbox {
    height: 242px;
    background-image: linear-gradient(to right, #ee4760, #c8282b);
  }

  .pDiv {
    line-height: 30px;
    font-size: 16px;
  }

  .left-main p>img {
    display: block;
  }

  .left-main {
    color: #000;
    /* border-bottom: 1px dashed #676767; */
    padding-bottom: 30px;
    margin-bottom: 20px;
  }

  .left-main p {
    text-indent: 24px;
    line-height: 30px;
    margin: 0;
  }
  @media (max-width: 1200px) {

    .news-right,
    .return-btn {
      display: none;
    }

    .news-left {
      width: 100%;
    }

    .top-info {
      line-height: 20px;
    }

    .left-main {
      margin-bottom: 20px;
    }
  }
</style>
